import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const biologicalInformationAnalysis = [
    {
        path: "Metabolome",
        name: "Metabolome",
        component: () => import("@/views/biologicalInformationProcess/Metabolome/Metabolome.vue"),
    },   
    {
        //火山图
        path: "OmicsFlowVolcanoMap",
        name: "OmicsFlowVolcanoMap",
        component: () => import("@/views/biologicalInformationProcess/Metabolome/OmicsFlowVolcanoMap.vue"),
    },   
    
]
const WithTypes = biologicalInformationAnalysis.map(item => {
    if (!item.meta) {
        // 没有meta对象
        return {
            ...item,
            meta: {
                type: "代谢组",
            },
        };
    }

    // 有meta对象
    return {
        ...item,
        meta: {
            ...item.meta,
            type: "代谢组", // 替换成你想要的类型值
        },
    };
});

export default WithTypes