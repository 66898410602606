<template>
  <div class="title">
    <div class="left">
      <div class="img">
        <img ref="img" :src="titleImg" alt="图片加载失败" />
      </div>
      <div
        class="title-right"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        {{ titleName }}
        <span
          v-if="littleTitle && littleTitle.length > 0"
          style="color: #eac75c"
          >({{ littleTitle }})</span
        >
      </div>
    </div>
    <!--  -->
    <div class="right-share" v-if="isShow == true">
      <div class="txt_png png">
        <div class="png_title">CNS复现图文案例：</div>
        <svg
          t="1706595174991"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2420"
          width="32"
          height="32"
        >
          <path
            d="M849.92 51.2H174.08c-67.8656 0-122.88 55.0144-122.88 122.88v675.84c0 67.8656 55.0144 122.88 122.88 122.88h675.84c67.8656 0 122.88-55.0144 122.88-122.88V174.08c0-67.8656-55.0144-122.88-122.88-122.88zM448.18432 230.94272c176.98304-53.95968 267.17696 110.98624 267.17696 110.98624-32.59392-17.78176-130.39104-37.53472-235.09504 16.7936s-126.4384 172.87168-126.4384 172.87168c-42.56256-45.4144-44.4928-118.6304-44.4928-118.6304 5.03296-137.41568 138.84928-182.02112 138.84928-182.02112zM393.50784 796.42112c-256.12288-49.6384-197.85216-273.38752-133.81632-371.95264 0 0-2.88256 138.13248 130.22208 214.4 0 0 15.82592 7.1936 10.79296 30.21312l-5.03808 29.49632s-6.656 20.1472 6.02624 22.30272c0 0 4.04992 0 13.39904-6.4768l48.92672-32.37376s10.07104-7.1936 23.01952-5.03808c12.94848 2.16064 95.68768 23.74656 177.70496-44.60032-0.00512 0-15.10912 213.67296-271.23712 164.02944z m256.8448-19.42016c16.54784-7.9104 97.1264-102.8864 58.98752-231.66464s-167.6288-157.55776-167.6288-157.55776c66.19136-28.0576 143.89248-7.19872 143.89248-7.19872 117.9904 34.5344 131.6608 146.77504 131.6608 146.77504 23.01952 200.71936-166.912 249.64608-166.912 249.64608z"
            fill="#01CC7A"
            p-id="2421"
          ></path>
        </svg>
      </div>
      <ul class="list_hover">
        <li v-for="(item, index) in this.articleLocation" :key="index">
          <span>{{ index + 1 }}.</span>
          <a :href="item.location" :title="item.title" target="_Blank">
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
    <div class="right-share" v-else></div>
    <div class="videos" v-if="videoIsShow == true" style="margin-left: 22px">
      <div class="video_png png">
        <div class="png_title">视频演示：</div>
        <a :href="videoUrl" target="_Blank">
          <svg
            t="1712488629154"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="7707"
            width="40"
            height="40"
            style="cursor: pointer"
          >
            <path
              d="M585.140148 533.712593l333.539556 198.542222a37.925926 37.925926 0 0 0 57.325037-32.616296v-331.851852a37.925926 37.925926 0 0 0-57.325037-32.616297l-333.539556 198.542223z"
              fill="#0CCA8A"
              fill-opacity=".5"
              p-id="7708"
            ></path>
            <path
              d="M714.031407 208.004741a75.851852 75.851852 0 0 1 75.851852 75.851852v481.09037a75.851852 75.851852 0 0 1-75.851852 75.851852H139.851852a75.851852 75.851852 0 0 1-75.851852-75.851852V283.875556a75.851852 75.851852 0 0 1 75.851852-75.851852h574.179555zM362.496 383.81037a37.925926 37.925926 0 0 0-37.925926 37.925926v205.330963a37.925926 37.925926 0 0 0 56.50963 33.052445l182.518518-102.665482a37.925926 37.925926 0 0 0 0-66.104889l-182.499555-102.665481a37.925926 37.925926 0 0 0-18.583704-4.873482z"
              fill="#0CCA8A"
              p-id="7709"
            ></path></svg
        ></a>
      </div>
    </div>
    <div class="videos" v-else></div>
    <!-- (isfav == true && collected == true) || cancellation1 == false" -->
    <div class="icon1" v-if="isfav == true">
      <span>收藏：</span>
      <img
        style="width: 25px"
        @click="cancellation(chartId)"
        src="./img/icon2.png"
        alt=""
      />
    </div>
    <!-- v-show="(isfav == false && cancellation1 == true) || collected == true" -->
    <div class="icon1" v-else>
      <span>收藏：</span>
      <img
        @click="addCollect(chartId)"
        style="width: 25px"
        src="./img/icon11.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
  data() {
    return {
      idData: null, //要取消收藏的id
      collectData: null,
      chartId: null,
      cancellation1: true, //判断是否取消收藏
      collected: false, // 判断是否为收藏
      isfav: null, //展示的收藏状态
      titleImg: null,
      titleName: null,
      littleTitle: null,
      pid: null,
      id: null,
      URL: require("../../../assets/img/default_userPhoto/lazy.gif"),
      isShow: false,
      videoIsShow: false,
      videoUrl: null,
      articleLocation: null,
      // 模态框的数据
      modalOpen: false,
      isDragging: false,
      // modalOpen1: false,
      // isDragging1: false,
      mouseX: 0,
      mouseY: 0,
      modalX: 0,
      modalY: 0,
      offsetX: 0,
      offsetY: 0,
    };
  },
  created() {
    // console.log(this.$route.query.pid);
    this.pid = this.$route.query.pid;
    this.getCollect();
    this.getChartData();
  },
  mounted() {},
  methods: {
    // 收藏列表
    async getCollect() {
      let params = {
        type: 1,
      };
      this.collectData = await this.$api.collect(params);
      // console.log(this.collectData);
    },
    // 添加收藏图表
    async addCollect(id) {
      this.isfav = true;
      // console.log(id);
      let params = {
        type: 1,
        action: "add",
        fid: id,
      };
      const collectData = await this.$api.collect(params);

      // console.log(collectData);
    },
    // 取消收藏
    async cancellation(id) {
      this.isfav = false;
      // console.log(this.collectData);
      this.collectData.data.data.forEach((item) => {
        // console.log(item);
        if (item.fid == id) {
          this.idData = item.id;
        } else {
          // console.log(666666);
        }
      });

      if (this.idData != null) {
        // console.log(this.idData);
        let params = {
          type: 1,
          action: "delete",
          id: this.idData,
        };
        const deleteData = await this.$api.collect(params);
        // console.log(deleteData);
      }
    },
    async getChartData() {
      let formData = new FormData();
      formData.append("pid", this.pid);
      const location_data = await this.$api.getDetailData(formData);
      // console.log(location_data);
      this.chartId = location_data[0].id;
      this.isfav = location_data[0].isfav;
      // console.log(this.isfav);
      if (location_data[0].articles.length > 0) {
        // console.log(location_data[0].articles[0]);
        this.isShow = true;
        // console.log(66, this.isShow);
        this.articleLocation = location_data[0].articles;
      } else {
        this.isShow = false;
      }

      if (location_data[0].vlink != "") {
        // console.log(location_data[0].vlink);
        this.videoIsShow = true;
        this.videoUrl = location_data[0].vlink;
        // this.videoUrl = location_data[0].articles[0].location;
        // this.articleLocation = location_data[0].articles;
      } else {
        this.videoIsShow = false;
      }

      this.titleImg = location_data[0].pics;
      this.titleName = location_data[0].name;
      this.littleTitle = location_data[0].title;
      // console.log(this.titleImg, this.titleName, this.littleTitle);
      if (this.titleImg.includes("/img/")) {
        // 如果包含 "/img/"，去掉 "/img/"
        this.titleImg = this.titleImg.replace("/img/", "");
      } else {
        // 如果不包含 "/img/"，在值前面拼接上 "https://cnsknowall.com"
        this.titleImg = window.g.baseUrl2 + this.titleImg;
      }
      this.$nextTick(() => {
        // 获取到对应的 img 元素引用
        const imgElement = this.$refs.img;

        // 修改 img 元素的 src 属性
        if (imgElement) {
          imgElement.src = this.titleImg;
        }
      });
      await bus.$emit("info", location_data);
    },

    // 打开视频公众号
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  // width: 46%;
  justify-content: space-between;
  .icon1 {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .img {
    width: 70px;
    height: 70px;

    img {
      width: 100%;
      height: 100%;
      // border-radius: 50%;
    }
  }
  .left {
    display: flex;
    height: auto !important;
    // width: 300px;
  }
  .title-right {
    font-size: 20px;
    font-weight: 700;
    color: #636e7b;
    // line-height: 70px;
    margin-left: 20px;
    margin-right: 50px;
  }
  .right-share {
    height: 32px;
    margin-left: 15px;
    position: relative;

    .list_hover {
      width: 720px;
      z-index: 99;
      display: none;
      position: absolute;
      // left: -10px;
      left: -209px;
      max-height: 300px;
      // background: rgba(255, 255, 255, 0.6);
      background: rgba(204, 204, 204, 0.1);
      background-image: url(./img/bg11.png);
      background-repeat: no-repeat;
      background-size: cover;
      /* background-position: center; */
      /* background-attachment: fixed; */
      overflow-y: auto;
      // background-color: #ffffff;
      padding: 15px;
      padding-top: 35px;
      overflow-y: auto;
      // background: rgba(50, 50, 50, 0.3); /* 设置背景颜色为灰黑色，透明度为0.3 */
      li {
        list-style: none;
        font-size: 15px;
        // width: 500px;
        height: 30px;
        line-height: 30px;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 3px;
        // background: #fff;
        color: #ccc;
        cursor: pointer;
        a {
          color: #666;
          text-decoration: none;
        }
      }
      li:hover a {
        color: #26a8d4;
        border-bottom: 1px solid #cbeff0;
      }
    }
  }

  .right-share:hover .list_hover {
    display: block;
  }
}
.png {
  display: flex;
  align-items: center;
  .png_title {
    font-size: 18px;
  }
}
</style>