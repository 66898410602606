<template>
  <div class="select__options">
    <label class="labelFontSize item">{{ label }}:</label>
    <el-select v-model="fontFamily" placeholder="请选择" @change="fontFamilyFun" :style="'width:'+width+';'">
      <el-option v-for="item in FontOptions" :key="item.value" :label="item.label" :value="item.value"/>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value:{
      type: String,
    },
    label:{
      type: String,
      default: "字体选择",
    },
    width:{
      type: String,
      default: "217px",
    },
    option:{
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fontFamily: this.value,
      options: [],
      // 字体
      FontOptions: [
        {value: "Times New Roman",label: "Times New Roman",},
        {value: "Arial",label: "Arial",},
        {value: "Palatino Linotype",label: "Palatino Linotype",},
        {value: "Calibri",label: "Calibri",},
        {value: "verdana",label: "verdana",},
        {value: "微软雅黑",label: "微软雅黑",},
        {value: "宋体",label: "宋体",},
        {value: "仿宋",label: "仿宋",},
        {value: "楷体",label: "楷体",},
        {value: "黑体",label: "黑体",},
      ],
      nameLocationOptions: [
        {value: "start",label: "起始",},
        {value: "center",label: "居中",},
        {value: "end",label: "结束",},
      ],
    };
  },
  mounted() {
    if (this.options === 0) {
      this.fontFamilyOptions = this.FontOptions
    }else if (this.options === 1) {
      this.fontFamilyOptions = this.nameLocationOptions
    }else{
      console.log(this.options);
    }
  },
  methods: {
    fontFamilyFun() {
      this.$emit("input", this.fontFamily); // 通过 input 事件更新父组件的数据
      this.$emit("drawChart"); // 触发自定义事件
    },
  },
};
</script>

<style scoped>
.select__options{
  margin:0;
  display:flex;
  align-items:center;
  justify-content:space-between;
  position:relative;
}
</style>