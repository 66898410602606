import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/iconfont/iconfont.css'
import App from './App.vue';
import router from './router/router';
import VueRouter from 'vue-router';
import * as echarts from 'echarts'
import Highcharts from 'highcharts/highstock';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsVue from "highcharts-vue"
import Highmaps from 'highcharts/modules/map';
import func from '././utils/func'
import doResize from "./assets/doResize";
import echartsLegendHelper from "./utils/legen.js";

Vue.use(func);

import axios from "axios";
import VueAxios from "vue-axios";
import api from './api'
import request from './utils/request';
import VueDragResize from 'vue-drag-resize'
// import './assets/phylotree.css'
import Blod from './compontens/tool/Blod'
import Blods from './compontens/tool/Blod1'
import FontFamily from './compontens/tool/Family';
import Range from './compontens/tool/Range';
import labelAngle from "./compontens/tool/labelAngle";
import Selects from "./compontens/tool/Selects";
import colors from "./compontens/tool/ColorS";
import downloadChart from "./compontens/tool/downloadChart";
import downloadChart2 from "./compontens/tool/downloadChart2";
import TwoCategories from "./compontens/tool/TwoCategories";
import AxisVal from "./compontens/tool/AxisVal";
import SymbolSelect from "./compontens/tool/SymbolSelect";
// 选择文件上传组件
import Upload from "./compontens/tool/upload";
import uploadVeen from "./compontens/tool/uploadVeen";
import threeuploadFiles from "./compontens/tool/threeuploadFiles";
import uploadFiles from "./compontens/tool/uploadFiles";
// 全局引入表格上传组件
import Copy from "./compontens/Copy";
import threeCopy from "./compontens/tool/threeCopy.vue";
import Copys from "./compontens/tool/Copys";
// 下载压缩包
import downloadPackage from './compontens/Buttons/downloadPackage.vue'
import Reset from "./compontens/Buttons/Reset.vue"
import Save from "./compontens/Buttons/Save.vue"
// 显示付款吗
import VueQrcode from 'vue-qrcode-component';
import TitleImg from './compontens/tool/ChartDetail/TitleImg'
import Description from './compontens/tool/ChartDetail/Description'

import Charts from './compontens/tool/ChartDetail/Charts'
import ChartResize from './compontens/tool/ChartDetail/chartResize'
import ChartsImg from './compontens/tool/ChartDetail/ChartsTwo'
import adjust from './compontens/tool/adjust'
import colorSelect from "./compontens/tool/colorSelect";
import HBreadcrumb from "./compontens/tool/ChartDetail/HBreadcrumb.vue";
import CopyPlus from "./compontens/CopyPlus";
import CopyPlusTwo from "./compontens/CopyPlusTwo";
import CopyPlusThree from "./compontens/CopyPlusThree";
import CopyPlusExcel from "./compontens/CopyPlusExcel";
import CopyPlusExcelTwo from "./compontens/CopyPlusExcelTwo";
import CopyPlusExcelThree from "./compontens/CopyPlusExcelThree";
Vue.component('CopyPlus', CopyPlus)
Vue.component('CopyPlusTwo', CopyPlusTwo)
Vue.component('CopyPlusThree', CopyPlusThree)
Vue.component('CopyPlusExcel', CopyPlusExcel)
Vue.component('CopyPlusExcelTwo', CopyPlusExcelTwo)
Vue.component('CopyPlusExcelThree', CopyPlusExcelThree)
Vue.component('qrcode-vue', VueQrcode);
import Talking from './compontens/tool/Talking'
Vue.component('Talking', Talking)
//注册为全局组件
Vue.component('Blod', Blod)
Vue.component('Blods', Blods)
Vue.component('FontFamily', FontFamily)
Vue.component('Range', Range)
Vue.component('Copy', Copy)
Vue.component('Upload', Upload)
Vue.component('uploadVeen', uploadVeen)
Vue.component('uploadFiles', uploadFiles)
Vue.component('threeuploadFiles', threeuploadFiles)
Vue.component('threeCopy', threeCopy)
Vue.component('Copys', Copys)
Vue.component('colors', colors)
Vue.component('downloadChart', downloadChart)
Vue.component('downloadChart2', downloadChart2)
Vue.component('TwoCategories', TwoCategories)
Vue.component('downloadPackage', downloadPackage)
Vue.component('labelAngle', labelAngle)
Vue.component('Selects', Selects)
Vue.component('TitleImg', TitleImg)
Vue.component('Description', Description)
Vue.component('Charts', Charts)
Vue.component('ChartResize', ChartResize)
Vue.component('ChartsImg', ChartsImg)
Vue.component('adjust', adjust)
Vue.component('colorSelect', colorSelect)
Vue.component('HBreadcrumb', HBreadcrumb)
Vue.component('AxisVal', AxisVal)
Vue.component('Reset', Reset)
Vue.component('Save', Save)
Vue.component('SymbolSelect', SymbolSelect)

// 24.6.27添加
import xAxisSet_D from './compontens/RightSetComponent_D/xAxisSet_D'
Vue.component('xAxisSet', xAxisSet_D)
// 24.8.28添加
import colorPicker_D from './compontens/RightSetComponent_D/colorPicker_D'
Vue.component('colorPicker', colorPicker_D)
// 24.8.29添加
import Slider_D from './compontens/RightSetComponent_D/Slider_D'
Vue.component('Slider', Slider_D)
import Select_D from './compontens/RightSetComponent_D/Select_D'
Vue.component('Select', Select_D)







Vue.component('vue-drag-resize', VueDragResize)
require("highcharts/modules/exporting")(Highcharts)

Highcharts3D(Highcharts);
Highmaps(Highcharts);
Vue.use(HighchartsVue)

Vue.prototype.axios = axios
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.prototype.$api = api  // 将封装的api接口挂载到实例上
Vue.prototype.$get = request.get  // 将封装的get请求挂载到实例上
Vue.prototype.$post = request.post  // 将封装的post请求挂载到实例上
// Vue.prototype.$G2 = G2; // 手动注册G2

// 创建一个懒加载自定义指令
Vue.directive('lazyload', {
  bind(el, binding) {
    const options = {
      root: null,
      rootMargin: '50px',
      threshold: 0.6,
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          // 延迟加载图片
          // setTimeout(() => {
          lazyImage.src = binding.value;
          // }, 500);
          observer.unobserve(lazyImage);
        }
      });
    }, options);

    observer.observe(el);
  },
});

//  获取所有Cookie
let allCookies = document.cookie;
// 解析Cookie字符串为键值对
let cookiesArray = allCookies.split('; ');
let cookies = {};
cookiesArray.forEach(cookie => {
  let parts = cookie.split('=');
  let name = parts[0];
  let value = parts[1];
  cookies[name] = value;
});
// 现在你可以通过键名获取特定的Cookie值
var myCookieValue = cookies['token'];
// console.log(myCookieValue);
router.beforeEach((to, from, next) => {
  // console.log(to, from);
  if (myCookieValue) {
    next();
  } else {
    //在没有token的前提下，to下面的path是否为/login，如果不是则页面跳转到登录页面
    if (to.path == "/HomePage" || to.path == "/Login") {
      next();
      // }else if(to.path == "/Login"){
      //   next();
    } else {
      // this.$message({
      //   type: 'info',
      //   message: '请先登录'
      // });     
      next({ path: "/HomePage" }); //跳转页面到login页
    }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
  console.log = function () { }
  console.error = function () { }
  console.dir = function () { }
}
new Vue({
  render: h => h(App),
  lintOnsave: false,
  router,
}).$mount('#app')
