<template>
  <div id="color" style="display: flex; justify-content: center; align-items: center">
    <el-color-picker v-model="color" show-alpha :predefine="predefineColors" @active-change="activeChange" />
    <input type="color" v-model="color" @input="activeChange1" />
  </div>
</template>

<script>
export default {
  name: "LetterMarkDiagramChart",
  props: {
    value: {
      type: String,
      default: null,
    },
    index: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      color: this.value,
      predefineColors: [
        "#190707",
        "#2A0A0A",
        "#3B0B0B",
        "#610B0B",
        "#8A0808",
        "#B40404",
        "#DF0101",
        "#FF0000",
        "#FE2E2E",
        "#FA5858",
        "#F78181",
        "#F5A9A9",
        "#F6CECE",
        "#F8E0E0",
        "#FBEFEF",
        "#190B07",
        "#2A120A",
        "#3B170B",
        "#61210B",
        "#8A2908",
        "#B43104",
        "#DF3A01",
        "#FF4000",
        "#FE642E",
        "#FA8258",
        "#F79F81",
        "#F5BCA9",
        "#F6D8CE",
        "#F8E6E0",
        "#FBF2EF",
        "#191007",
        "#2A1B0A",
        "#3B240B",
        "#61380B",
        "#8A4B08",
        "#B45F04",
        "#DF7401",
        "#FF8000",
        "#FE9A2E",
        "#FAAC58",
        "#F7BE81",
        "#F5D0A9",
        "#F6E3CE",
        "#F8ECE0",
        "#FBF5EF",
        "#181407",
        "#29220A",
        "#3A2F0B",
        "#5F4C0B",
        "#886A08",
        "#B18904",
        "#DBA901",
        "#FFBF00",
        "#FACC2E",
        "#F7D358",
        "#F5DA81",
        "#F3E2A9",
        "#F5ECCE",
        "#F7F2E0",
        "#FBF8EF",
        "#181907",
        "#292A0A",
        "#393B0B",
        "#5E610B",
        "#868A08",
        "#AEB404",
        "#D7DF01",
        "#FFFF00",
        "#F7FE2E",
        "#F4FA58",
        "#F3F781",
        "#F2F5A9",
        "#F5F6CE",
        "#F7F8E0",
        "#FBFBEF",
        "#141907",
        "#222A0A",
        "#2E3B0B",
        "#4B610B",
        "#688A08",
        "#86B404",
        "#A5DF00",
        "#BFFF00",
        "#C8FE2E",
        "#D0FA58",
        "#D8F781",
        "#E1F5A9",
        "#ECF6CE",
        "#F1F8E0",
        "#F8FBEF",
        "#101907",
        "#1B2A0A",
        "#243B0B",
        "#38610B",
        "#4B8A08",
        "#5FB404",
        "#74DF00",
        "#80FF00",
        "#9AFE2E",
        "#ACFA58",
        "#BEF781",
        "#D0F5A9",
        "#E3F6CE",
        "#ECF8E0",
        "#F5FBEF",
        "#0B1907",
        "#122A0A",
        "#173B0B",
        "#21610B",
        "#298A08",
        "#31B404",
        "#3ADF00",
        "#40FF00",
        "#64FE2E",
        "#82FA58",
        "#9FF781",
        "#BCF5A9",
        "#D8F6CE",
        "#E6F8E0",
        "#F2FBEF",
        "#071907",
        "#0A2A0A",
        "#0B3B0B",
        "#0B610B",
        "#088A08",
        "#04B404",
        "#01DF01",
        "#00FF00",
        "#2EFE2E",
        "#58FA58",
        "#81F781",
        "#A9F5A9",
        "#CEF6CE",
        "#E0F8E0",
        "#EFFBEF",
        "#07190B",
        "#0A2A12",
        "#0B3B17",
        "#0B6121",
        "#088A29",
        "#04B431",
        "#01DF3A",
        "#00FF40",
        "#2EFE64",
        "#58FA82",
        "#81F79F",
        "#A9F5BC",
        "#CEF6D8",
        "#E0F8E6",
        "#EFFBF2",
        "#071910",
        "#0A2A1B",
        "#0B3B24",
        "#0B6138",
        "#088A4B",
        "#04B45F",
        "#01DF74",
        "#00FF80",
        "#2EFE9A",
        "#58FAAC",
        "#81F7BE",
        "#A9F5D0",
        "#CEF6E3",
        "#E0F8EC",
        "#EFFBF5",
        "#071914",
        "#0A2A22",
        "#0B3B2E",
        "#0B614B",
        "#088A68",
        "#04B486",
        "#01DFA5",
        "#00FFBF",
        "#2EFEC8",
        "#58FAD0",
        "#81F7D8",
        "#A9F5E1",
        "#CEF6EC",
        "#E0F8F1",
        "#EFFBF8",
        "#071918",
        "#0A2A29",
        "#0B3B39",
        "#0B615E",
        "#088A85",
        "#04B4AE",
        "#01DFD7",
        "#00FFFF",
        "#2EFEF7",
        "#58FAF4",
        "#81F7F3",
        "#A9F5F2",
        "#CEF6F5",
        "#E0F8F7",
        "#EFFBFB",
        "#071418",
        "#0A2229",
        "#0B2F3A",
        "#0B4C5F",
        "#086A87",
        "#0489B1",
        "#01A9DB",
        "#00BFFF",
        "#2ECCFA",
        "#58D3F7",
        "#81DAF5",
        "#A9E2F3",
        "#CEECF5",
        "#E0F2F7",
        "#EFF8FB",
        "#071019",
        "#0A1B2A",
        "#0B243B",
        "#0B3861",
        "#084B8A",
        "#045FB4",
        "#0174DF",
        "#0080FF",
        "#2E9AFE",
        "#58ACFA",
        "#81BEF7",
        "#A9D0F5",
        "#CEE3F6",
        "#E0ECF8",
        "#EFF5FB",
        "#070B19",
        "#0A122A",
        "#0B173B",
        "#0B2161",
        "#08298A",
        "#0431B4",
        "#013ADF",
        "#0040FF",
        "#2E64FE",
        "#5882FA",
        "#819FF7",
        "#A9BCF5",
        "#CED8F6",
        "#E0E6F8",
        "#EFF2FB",
        "#070719",
        "#0A0A2A",
        "#0B0B3B",
        "#0B0B61",
        "#08088A",
        "#0404B4",
        "#0101DF",
        "#0000FF",
        "#2E2EFE",
        "#5858FA",
        "#8181F7",
        "#A9A9F5",
        "#CECEF6",
        "#E0E0F8",
        "#EFEFFB",
        "#0B0719",
        "#120A2A",
        "#170B3B",
        "#210B61",
        "#29088A",
        "#3104B4",
        "#3A01DF",
        "#4000FF",
        "#642EFE",
        "#8258FA",
        "#9F81F7",
        "#BCA9F5",
        "#D8CEF6",
        "#E6E0F8",
        "#F2EFFB",
        "#100719",
        "#1B0A2A",
        "#240B3B",
        "#380B61",
        "#4B088A",
        "#5F04B4",
        "#7401DF",
        "#8000FF",
        "#9A2EFE",
        "#AC58FA",
        "#BE81F7",
        "#D0A9F5",
        "#E3CEF6",
        "#ECE0F8",
        "#F5EFFB",
        "#140718",
        "#220A29",
        "#2F0B3A",
        "#4C0B5F",
        "#6A0888",
        "#8904B1",
        "#A901DB",
        "#BF00FF",
        "#CC2EFA",
        "#D358F7",
        "#DA81F5",
        "#E2A9F3",
        "#ECCEF5",
        "#F2E0F7",
        "#F8EFFB",
        "#190718",
        "#2A0A29",
        "#3B0B39",
        "#610B5E",
        "#8A0886",
        "#B404AE",
        "#DF01D7",
        "#FF00FF",
        "#FE2EF7",
        "#FA58F4",
        "#F781F3",
        "#F5A9F2",
        "#F6CEF5",
        "#F8E0F7",
        "#FBEFFB",
        "#190714",
        "#2A0A22",
        "#3B0B2E",
        "#610B4B",
        "#8A0868",
        "#B40486",
        "#DF01A5",
        "#FF00BF",
        "#FE2EC8",
        "#FA58D0",
        "#F781D8",
        "#F5A9E1",
        "#F6CEEC",
        "#F8E0F1",
        "#FBEFF8",
        "#190710",
        "#2A0A1B",
        "#3B0B24",
        "#610B38",
        "#8A084B",
        "#B4045F",
        "#DF0174",
        "#FF0080",
        "#FE2E9A",
        "#FA58AC",
        "#F781BE",
        "#F5A9D0",
        "#F6CEE3",
        "#F8E0EC",
        "#FBEFF5",
        "#19070B",
        "#2A0A12",
        "#3B0B17",
        "#610B21",
        "#8A0829",
        "#B40431",
        "#DF013A",
        "#FF0040",
        "#FE2E64",
        "#FA5882",
        "#F7819F",
        "#F5A9BC",
        "#F6CED8",
        "#F8E0E6",
        "#FBEFF2",
        "#000000",
        "#151515",
        "#1C1C1C",
        "#2E2E2E",
        "#424242",
        "#585858",
        "#6E6E6E",
        "#848484",
        "#A4A4A4",
        "#BDBDBD",
        "#D8D8D8",
        "#E6E6E6",
        "#F2F2F2",
        "#FAFAFA",
        "#FFFFFF",
      ],
    };
  },
  methods: {
    activeChange(value) {
      this.color = this.getHexColor(value);
      this.$emit("input", this.color);
      this.$emit("handleColorChange"); // 触发自定义事件
    },
    //rgba转十六进制颜色
    getHexColor(color) {
      // console.log(color);
      var values = color
        .replace(/rgba?\(/, "")
        .replace(/\)/, "")
        .replace(/[\s+]/g, "")
        .split(",");
      var a = parseFloat(values[3] || 1),
        r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
        g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
        b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
      return (
        "#" +
        ("0" + r.toString(16)).slice(-2) +
        ("0" + g.toString(16)).slice(-2) +
        ("0" + b.toString(16)).slice(-2)
      );
    },
    activeChange1() {
      console.log(this.color);
      this.$emit("input", this.color);
      // this.$emit("input", this.color);
      if (this.index === null) {
        return;
      } else {
        this.$emit("handleColorChange"); // 触发自定义事件
      }
    },
  },
  watch: {
    value(value) {
      this.color = value; // 当父组件颜色值更新时，同步本地颜色值
    },
  },
};
</script>

<style lang="scss">
.el-color-dropdown {
  width: 595px;
}

.el-color-svpanel {
  width: 550px;
  justify-content: center;
  align-items: center;
}

.el-color-dropdown__main-wrapper {
  position: relative;
  margin-top: 370px;

  .el-color-hue-slider {
    width: 20px;
  }
}

.el-color-alpha-slider {
  width: 580px;
}

.el-color-predefine {
  position: relative;
  margin-top: -570px;
  width: 580px;
  height: 365px;
  flex-wrap: nowrap !important;
  border: 1px solid #eee;

  // overflow-y:scroll;
  .el-color-predefine__colors {
    flex-direction: column-reverse;
    padding-right: 5px;

    // flex-wrap: nowrap !important;
    .el-color-predefine__color-selector {
      margin: 2px 1px;
    }
  }
}

.el-color-dropdown__btns {
  position: relative;
  margin-top: 220px;

  .el-color-dropdown__value {
    height: 50px;
    width: 580px;

    .el-input__inner {
      height: 50px;
      font-size: 25px;
    }
  }

  .el-color-dropdown__link-btn {
    display: none;
  }

  .el-color-dropdown__btn {
    display: none;
  }
}
</style>
