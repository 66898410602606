<template>
    <div>
        <div class="littleTit" style="color: rgba(0, 0, 0, 0.8); font-weight: 700; font-size: 15px; margin: 0px 0;">
            图形配色方案
        </div>
        <el-popover placement="left" width="225" v-model="visible">
            <div style="
                        max-height: 600px;
                        overflow: auto;
                        overflow-x: hidden;
                    ">
                <el-switch v-model="value1" inactive-text="一键反转" @change="reverseColors(colorList)">
                </el-switch>
                <h6>多色渐变</h6>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#000095, #00bfff, #8eff70, #ffbf00, #ff0000)` }"
                    @click="setColor1(colorList.classics32, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#313695,#4575b4,#e0f3f8,#fdae61,#d73027)`,
        }" @click="setColor1(colorList.classics1, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#0571B0, #92C5DE, #FFFFFF, #F4A582, #CA0020)` }"
                    @click="setColor1(colorList.classics31, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#D15047, #c98c8c, #93829B, #80b4df, #3793DE)`,
        }" @click="setColor1(colorList.classics4, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#d47f1a, #e5b982, #f0dec4, #76c4b8, #28a89b)`,
        }" @click="setColor1(colorList.classics5, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#c72281, #e7a8cc, #f5e5ee, #bacea8, #4d9221)`,
        }" @click="setColor1(colorList.classics6, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#5c50a2, #78c9a4, #fddd89, #f57c49,#a20543)`,
        }" @click="setColor1(colorList.threeColor1, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#6d011f,#ef9a79,#f0d8c9,#78b4d5,#073569)`,
        }" @click="setColor1(colorList.threeColor2, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#5b3405,#bd7f2b,#efe6c2,#a9bcf5,#0040ff)`,
        }" @click="setColor1(colorList.threeColor6, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#0000aa, #4c78c1, #a2e3c3, #a67262, #aa0404)`,
        }" @click="setColor1(colorList.classics7, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#6e40aa, #4775de, #1eb8d0, #28ea8d, #79f659)`,
        }" @click="setColor1(colorList.classics8, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#b75e09, #e58f2b, #fae9d1, #8c7eb5, #2d004b)`,
        }" @click="setColor1(colorList.classics9, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#67001f, #b12631, #f6b69a, #929292, #545454)`,
        }" @click="setColor1(colorList.classics10, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#67001f, #b12631, #f6b69a, #73c364, #289b51)`,
        }" @click="setColor1(colorList.classics11, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#ff0000, #ff6600, #ffee00, #4400ff, #9900ff)`,
        }" @click="setColor1(colorList.classics12, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#3976B0, #9cbbd8, #FFFFFF, #de999d, #BC323B)` }"
                    @click="setColor1(colorList.classics13, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#00FF00, #0a8a0a, #141414, #8a0a0a, #FF0000)` }"
                    @click="setColor1(colorList.classics14, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#1D1DFF, #8e8eff, #FFFFFF, #ff8585, #FF0A0A)` }"
                    @click="setColor1(colorList.classics15, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#0AA1FF, #85d0ff, #FFFFFF, #fae68b, #F5CC16)` }"
                    @click="setColor1(colorList.classics16, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#018571, #80CDC1, #FFFFFF, #DFC27D, #A6611A)` }"
                    @click="setColor1(colorList.classics17, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#13A3A6, #80C8C9, #FFFFFF, #DEAECF, #CB6CB1)` }"
                    @click="setColor1(colorList.classics18, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#76B259, #B6D1A5, #FFFFFF, #E0BDED, #CB88E8)` }"
                    @click="setColor1(colorList.classics19, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#008837, #A6DBA0, #FFFFFF, #C2A5CF, #7B3294)` }"
                    @click="setColor1(colorList.classics20, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#76B259, #B6D1A5, #FFFFFF, #F9B9B0, #F67F74)` }"
                    @click="setColor1(colorList.classics21, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#5E3C99, #B2ABD2, #FFFFFF, #FDB863, #E66101)` }"
                    @click="setColor1(colorList.classics22, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#8091E1, #B3BAE2, #FFFFFF, #E5AEB8, #DE758C)` }"
                    @click="setColor1(colorList.classics23, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#4A71BD, #B1BFE9, #FFFFFF, #EBB2B1, #B45555)` }"
                    @click="setColor1(colorList.classics24, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#C4C6FF, #E2E2FF, #FFFFFF, #F1E9AA, #DAD069)` }"
                    @click="setColor1(colorList.classics25, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#7A7DE2, #BAB5EA, #FFFFFF, #EDAB9B, #DA624A)` }"
                    @click="setColor1(colorList.classics26, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#1DC9F7, #C8F2FF, #FFFFFF, #FEE2F9, #EAA0E1)` }"
                    @click="setColor1(colorList.classics27, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#858E41, #BEC489, #FFFFFF, #E9B1B6, #D7738A)` }"
                    @click="setColor1(colorList.classics28, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#229996, #99BBAC, #FFFFFF, #E0A6A3, #D26684)` }"
                    @click="setColor1(colorList.classics29, false)"></div>
                <div :style="{ width: '180px', height: '20px', borderRadius: '3px', margin: '5px 0', background: `linear-gradient(to right,#218A86, #9ABAA0, #FFFFFF, #E7A877, #D06539)` }"
                    @click="setColor1(colorList.classics30, false)"></div>
                <h6>三色渐变</h6>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#a70226,#fedc87,#026b39)`,
        }" @click="setColor1(colorList.threeColor3, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#540f60,#e3d4e4,#1b7737)`,
        }" @click="setColor1(colorList.threeColor4, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#950457,#f6e0e5,#286519)`,
        }" @click="setColor1(colorList.threeColor5, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#fbfa99,#f48210,#aa305a,#1c0a40)`,
        }" @click="setColor1(colorList.threeColor7, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#f2ee22,#f0834c,#ab2592,#1e068d)`,
        }" @click="setColor1(colorList.threeColor8, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#fcfabc,#fc9d6f,#ac337a,#53137c,#100929)`,
        }" @click="setColor1(colorList.threeColor9, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#fae548,#727274,#012452)`,
        }" @click="setColor1(colorList.threeColor10, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#954734, #e8e2a7, #5195c5)`,
        }" @click="setColor1(colorList.threeColor11, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#e3c320,#4979b6,#6a1abb)`,
        }" @click="setColor1(colorList.classics2, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#f6e626,#219389,#440154)`,
        }" @click="setColor1(colorList.classics3, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to left,#c10b0b,#fa5858,#0761dd)`,
        }" @click="setColor1(colorList.classics0, false)"></div>
                <h6>两色渐变</h6>

                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#fef4f1,#f76ba1,#5f0070)`,
        }" @click="setColor1(colorList.twoColor1, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#ecf7fa,#6ac3a7,#00471c)`,
        }" @click="setColor1(colorList.twoColor2, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#e7f1f7,#91a1cc,#540252)`,
        }" @click="setColor1(colorList.twoColor3, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#f0f9ea,#80cdc2,#084384)`,
        }" @click="setColor1(colorList.twoColor4, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#feefd9,#fc8f5b,#881010)`,
        }" @click="setColor1(colorList.twoColor5, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#ffffff,#82aed2,#024166)`,
        }" @click="setColor1(colorList.twoColor6, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#f8fcca,#4ab9c2,#004c2c)`,
        }" @click="setColor1(colorList.twoColor7, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#fffbd1,#fd9728,#6d2705)`,
        }" @click="setColor1(colorList.twoColor8, false)"></div>
                <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#f2f0f6,#a19eca,#41047f)`,
        }" @click="setColor1(colorList.twoColor9, false)"></div>
            </div>
            <div :style="{
            width: '180px',
            height: '20px',
            borderRadius: '3px',
            margin: '5px 0',
            background: `linear-gradient(to right,#f2f0f6,#a19eca,#41047f)`,
        }" @click="setColor1(colorList.twoColor10, false)"></div>
            <div class="colorSchemes" slot="reference">
                <div v-if="colors.length == 5" class="selectColor" :style="{
            background: `linear-gradient(to right,${colors[0]}, ${colors[1]},${colors[2]},${colors[3]},${colors[4]})`,
        }"></div>
                <div v-if="colors.length == 4" class="selectColor" :style="{
            background: `linear-gradient(to right,${colors[0]}, ${colors[1]},${colors[2]},${colors[3]})`,
        }"></div>
                <div v-if="colors.length == 3" class="selectColor" :style="{
            background: `linear-gradient(to right,${colors[0]}, ${colors[1]},${colors[2]})`,
        }"></div>
                <i class="el-icon-caret-top"></i>
            </div>
        </el-popover>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
        },
    },
    data() {
        return {
            visible: false,
            colors: this.value,
            value1: false,
            status: true,
            // 颜色选择绑定
            colorList: {
                classics0: ["#0761dd", "#fa5858", "#c10b0b"],
                classics1: [
                    "#313695",
                    "#4575b4",
                    "#e0f3f8",
                    "#fdae61",
                    "#d73027",
                ],
                classics2: ["#e3c320", "#4979b6", "#6a1abb"],
                classics3: ["#f6e626", "#219389", "#440154"],
                classics4: [
                    "#D15047",
                    "#c98c8c",
                    "#93829B",
                    "#80b4df",
                    "#3793DE",
                ],
                classics5: ["#d47f1a", "#e5b982", "#f0dec4", "#76c4b8", "#28a89b"],
                classics6: [
                    "#c72281",
                    "#e7a8cc",
                    "#f5e5ee",
                    "#bacea8",
                    "#4d9221",
                ],
                classics7: [
                    "#0000aa",
                    "#4c78c1",
                    "#a2e3c3",
                    "#a67262",
                    "#aa0404",
                ],
                classics8: [
                    "#6e40aa",
                    "#4775de",
                    "#1eb8d0",
                    "#28ea8d",
                    "#79f659",
                ],
                classics9: [
                    "#b75e09",
                    "#e58f2b",
                    "#fae9d1",
                    "#8c7eb5",
                    "#2d004b",
                ],
                classics10: [
                    "#67001f",
                    "#b12631",
                    "#f6b69a",
                    "#929292",
                    "#545454",
                ],
                classics11: [
                    "#67001f",
                    "#b12631",
                    "#f6b69a",
                    "#73c364",
                    "#289b51",
                ],
                classics12: [
                    "#ff0000",
                    "#ff6600",
                    "#ffee00",
                    "#4400ff",
                    "#9900ff",
                ],
                classics13: ["#3976B0", "#9cbbd8", "#FFFFFF", "#de999d", "#BC323B"],
                classics14: ["#00FF00", "#0a8a0a", "#141414", "#8a0a0a", "#FF0000"],
                classics15: ["#1D1DFF", "#8e8eff", "#FFFFFF", "#ff8585", "#FF0A0A"],
                classics16: ["#0AA1FF", "#85d0ff", "#FFFFFF", "#fae68b", "#F5CC16"],
                classics17: ["#018571", "#80CDC1", "#FFFFFF", "#DFC27D", "#A6611A"],
                classics18: ["#13A3A6", "#80C8C9", "#FFFFFF", "#DEAECF", "#CB6CB1"],
                classics19: ["#76B259", "#B6D1A5", "#FFFFFF", "#E0BDED", "#CB88E8"],
                classics20: ["#008837", "#A6DBA0", "#FFFFFF", "#C2A5CF", "#7B3294"],
                classics21: ["#76B259", "#B6D1A5", "#FFFFFF", "#F9B9B0", "#F67F74"],
                classics22: ["#5E3C99", "#B2ABD2", "#FFFFFF", "#FDB863", "#E66101"],
                classics23: ["#8091E1", "#B3BAE2", "#FFFFFF", "#E5AEB8", "#DE758C"],
                classics24: ["#4A71BD", "#B1BFE9", "#FFFFFF", "#EBB2B1", "#B45555"],
                classics25: ["#C4C6FF", "#E2E2FF", "#FFFFFF", "#F1E9AA", "#DAD069"],
                classics26: ["#7A7DE2", "#BAB5EA", "#FFFFFF", "#EDAB9B", "#DA624A"],
                classics27: ["#1DC9F7", "#C8F2FF", "#FFFFFF", "#FEE2F9", "#EAA0E1"],
                classics28: ["#858E41", "#BEC489", "#FFFFFF", "#E9B1B6", "#D7738A"],
                classics29: ["#229996", "#99BBAC", "#FFFFFF", "#E0A6A3", "#D26684"],
                classics30: ["#218A86", "#9ABAA0", "#FFFFFF", "#E7A877", "#D06539"],
                classics31: ["#0571B0", "#92C5DE", "#FFFFFF", "#F4A582", "#CA0020"],
                classics32: ['#000095', '#00bfff', '#8eff70', "#ffbf00", "#ff0000"],
                threeColor1: [
                    "#5c50a2",
                    "#78c9a4",
                    "#fddd89",
                    "#f57c49",
                    "#a20543",
                ],
                threeColor2: [
                    "#6d011f",
                    "#ef9a79",
                    "#f0d8c9",
                    "#78b4d5",
                    "#073569",
                ],
                threeColor3: ["#a70226", "#fedc87", "#026b39"],
                threeColor4: ["#540f60", "#e3d4e4", "#1b7737"],
                threeColor5: ["#950457", "#f6e0e5", "#286519"],
                threeColor6: [
                    "#5b3405",
                    "#bd7f2b",
                    "#efe6c2",
                    "#a9bcf5",
                    "#0040ff",
                ],
                threeColor7: ["#fbfa99", "#f48210", "#aa305a", "#1c0a40"],
                threeColor8: ["#f2ee22", "#f0834c", "#ab2592", "#1e068d"],
                threeColor9: [
                    "#100929",
                    "#53137c",
                    "#ac337a",
                    "#fc9d6f",
                    "#fcfabc",
                ],
                threeColor10: ["#fae548", "#727274", "#012452"],
                threeColor11: ["#954734", "#e8e2a7", "#5195c5"],
                twoColor1: ["#fef4f1", "#f76ba1", "#5f0070"],
                twoColor2: ["#ecf7fa", "#6ac3a7", "#00471c"],
                twoColor3: ["#e7f1f7", "#91a1cc", "#540252"],
                twoColor4: ["#f0f9ea", "#80cdc2", "#084384"],
                twoColor5: ["#feefd9", "#fc8f5b", "#881010"],
                twoColor6: ["#ffffff", "#82aed2", "#024166"],
                twoColor7: ["#f8fcca", "#4ab9c2", "#004c2c"],
                twoColor8: ["#fffbd1", "#fd9728", "#6d2705"],
                twoColor9: ["#f2f0f6", "#a19eca", "#41047f"],
                twoColor10: ["#ffffff", "#ffedf0", "#ff688e"],

            },
        };
    },
    methods: {
        // 切换颜色
        setColor1(arr, status) {
            if (!status) {
                this.status = false;
            }
            this.colors = arr;
            this.$emit("input", this.colors);
        },
        reverseColors(colorObj) {
            for (let key in colorObj) {
                this.colorList[key] = colorObj[key].reverse();
            }
            if (this.status) {
                this.setColor1(this.colors.reverse(), true);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-popover {
    max-height: 600px !important;
    overflow: auto !important;
}

.colorSchemes {
    margin: 10px 0;
    width: 250px;
    height: 20px;
    border: 1px solid #eee;
    background-color: #eee;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .selectColor {
        width: 280px;
        height: 20px;
        border-radius: 3px;
    }
}
</style>